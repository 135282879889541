// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---pages-home-mdx": () => import("./../../../../pages/home.mdx" /* webpackChunkName: "component---pages-home-mdx" */),
  "component---pages-setup-environment-mdx": () => import("./../../../../pages/setup/environment.mdx" /* webpackChunkName: "component---pages-setup-environment-mdx" */),
  "component---pages-setup-install-mdx": () => import("./../../../../pages/setup/install.mdx" /* webpackChunkName: "component---pages-setup-install-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

